
import { Component, Vue, Watch } from 'vue-property-decorator';
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import CodeInput from 'vue-verification-code-input';
import Timer from '@/components/utilities/Timer.vue';
import InfoSide from '@/components/InfoSide.vue';
import { Socket } from 'vue-socket.io-extended'
import { auth, users} from '@/store/namespaces';
import AuthMethods from '@/store/auth/methods/auth.methods';
import UsersMethods from '@/store/users/methods/users.methods';
import store from '@/store';
import Loader from '@/components/utilities/Loader.vue';
import { User } from '../../interfaces/user.interface';
import { showToast } from '@/utils/global-functions';

@Component({
    components: {
        SvgIcon,
        CodeInput,
        Timer,
        InfoSide,
        Loader
    },
})
export default class SendEmailSuccess extends Vue {

    $refs: any = {}
    loginButton = {
        name: 'Iniciar sesión',
        text: '¿Recordaste tu Contraseña?',
        level: 1,
        redirect: '/inicio-sesion',
    };
    inputDisabled = false;
    expiredCode = false;
    invalidCode = false;
    validCode = false;
    loading = false
    
    @Socket()
    connect(){
       
    }

    created(){
    //    this.$socket.client.emit('new_connection', this.userData.email_user) 
    }

    onChange(v: any) {
       this.expiredCode = false;
       this.invalidCode = false;
    }

    async onComplete(code: any) {
        this.expiredCode = false;
        this.invalidCode = false;
        this.loading = true;
        let response = await this.validateCode({ident_user: store.state.auth.user.email_user, code: code})
        if (!response) showToast('Ha ocurrido un error al verificar el código','error')
        this.isCodeValid(response)
        this.loading = false;
    //     if (this.$socket.disconnected) {
    //         showToast('Su conexión a internet está inestable, intente más tarde', 'error')
    //         this.$socket.client.emit('new_connection',this.userData.email_user)
    //     }
    //     this.$socket.client.emit('verif_code', {
    //         ident_user: this.userData.email_user,
    //         code: code,
    //         email_user: this.userData.email_user
    //     })
    //    this.$socket.client.on('verif_code_response',this.isCodeValid)
    //    this.$socket.client.on('disconnect', (reason) => {
    //         showToast('Su conexión a internet está inestable, intente más tarde', 'error')
    //     }); 
    }

    isCodeValid(response: {msg: string}){
        if (response.msg === 'Invalid user') showToast('Usuario inválido','error')
        if (response.msg ==='Valid code'){
                this.inputDisabled = true;
                //this.$refs.timer.isCorrect();
                this.validCode = true
                this.$router.push({name: 'RecoverPassword'}).catch();
        }
        else if (response.msg ==='Expired code'){
                this.expiredCode = true;
                this.invalidCode = false;
        }
        else if (response.msg ==='Invalid code'){
                this.invalidCode = true;
                this.expiredCode = false;
        }
    }

    showExpiredText(){
        this.expiredCode = true;
    }

    async resendEmail(){
        this.$refs.loader.showLoader();
        let response = await this.sendEmail({email_user: store.state.auth.user.email_user, type: 'recoverPassword'});
        if (response.error){
            showToast(response.msg,'error')
        }
        else{
            this.$refs.timer.reset();
            this.expiredCode = false;
        }
        this.$refs.loader.hideLoader();
    }
    
    @auth.Action(AuthMethods.actions.SEND_EMAIL)
        sendEmail!: (data:{email_user: string, type: string}) => Promise<{error: boolean, msg: string}>
    @auth.Getter(AuthMethods.getters.GET_USER_DATA)
        userData: User;
    @users.Action(UsersMethods.actions.VALIDATE_CODE)
        validateCode!: (data: {ident_user: string, code: string}) => Promise<{msg: string, ident_user: string}>
}
